.global-styles {
  font-family: 'Times New Roman', Times, serif;
}

.headingStyle {
  padding-left: 10px;
  padding-top: 10px;
  background-color: lightpink;
}

.text-style {
  text-align: justify;
}

.flex-container {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  max-width: 750px;
  width: 100%;
}

.flex-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.wrapper {
  background: #ccc;
  overflow: hidden;
  transition: height 200ms;
}
