.text-position {
    padding-left: 15px;
}

.text-style-1 {
    text-align: justify;
    padding-left: 15px;
}

.text-style-2 {
    width: 900px;
}