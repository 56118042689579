* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Times New Roman", Times, serif;
  }
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    background-image: url('../src/components/images/img-home.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .Test {
    background-color: black;
  }

  .products {
    background-image: url('../src/components/images/img-10.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    background-image: url('../src/components/images/img-8.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .ImageStyle {
    /* width: 500px; 
       height: 500px;*/
       position: absolute;
       top: 100;
      
  }

  .imageContainer {
    z-index: 1;
  }

  .ImageSize {
    width: 100%; 
    height: 100%;
  }

  .pageStyle {
    padding-top: 100px;
  }

  .textStyle {
    color: black;
  }

  .containerContactG {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  
    justify-content: space-between;
  }

  .containerC {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-style: solid;
    max-width: 315px;
    min-width: 315px;
  }

  .containerCWithVideo{
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-style: solid;
  }

  .containerCCenter {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-style: solid;
    max-width: 315px;
  }

  .Item1 {
    flex: 1;
  }

  .Item1 {
    flex: 2;
  }

  .Item1 {
    flex: 3;
  }

  .Item4 {
    flex: 3;
  }

  .containerCHeader {
    background-color: gray;
  }

  .constainerCText {
    padding-top: 10px;
  }

  .containerCImage {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
  }

  .ImgRespons {
    max-width: 100%;
    height: auto;
  }

  .ImgRespons2 {
    max-width: 60%;
    height: auto;
    margin-bottom: 10%;
    box-shadow: 20px 15px 9px grey;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .ContainerLocation {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }

  .ContainerVideo {
    height: 100%;
    width: 100%;
    position: inherit;
  }

  .mainContainer {
    background-color: green;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  
    justify-content: space-between;
  }

  .Item {
    background-color: aqua;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-style: solid;
    max-width: 315px;
  }

  .CarouselContainer {
    display: flex;
    justify-content: center;
  }

  .DayTimeContainer {
    display: flex;  
  }

  .DayContainer {
    width: 30px;
  } 

  .FacebookContainer {
    width: 150px;
    height: 150px;
    padding-left: 15px;
  }

